@tailwind base;
@tailwind components;
@tailwind utilities;

$grayColor: #707070;
$orangeColor: #fa7e4a;
$orangeDark: #d66636;
$bigTextColor: #f5e394;
$mediumColor: #2d2d2d;

.topBarDiv {
	background-color: white;
}

.topBarFonts {
	font-size: 20px;
	font-weight: bold;
	cursor: pointer;

	@media (max-width: 1400px) {
		font-size: 16px;
	}
}

.flagsDiv {
	right: 0;
	top: 5px;
}

.phoneTopBarSpan {
	font-size: 25px;
	font-weight: bold;

	@media (max-width: 1400px) {
		font-size: 20px;
	}
}

.menu {
	font-size: 34px !important;
	color: #707070;
}

.mobileNav {
	left: 0;
	width: 100%;
	top: -165px;
	padding-top: 40px;
	padding-bottom: 10px;
	background-color: white;
	-webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
	position: fixed;
	z-index: 5;

	border-bottom: 1px solid $grayColor;

	@media (min-width: 641px) {
		top: -135px;
	}
}

.online {
	position: static;
}

.bigText {
	font-size: 100px;
	color: $bigTextColor;
	font-weight: bold;
	line-height: 65px;

	@media (max-width: 1280px) {
		font-size: 70px;
	}

	@media (max-width: 1024px) {
		font-size: 50px;
	}
}

.mediumText {
	font-size: 25px;
	color: $mediumColor;
	font-weight: 500;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
}

.filledButton {
	background-color: $orangeColor;
	border-radius: 35px;
	padding: 10px 20px;
	transition: 500ms;
}

.filledButton:hover {
	background-color: $orangeDark;
}

.whiteButton {
	border: 1px solid $orangeColor;
	border-radius: 35px;
	padding: 10px 20px;
	color: $orangeColor;

	transition: 500ms;

	.whiteIconButton {
		color: $orangeColor;
	}
}

.whiteButton:hover {
	background-color: $orangeColor;
	color: white;

	.whiteIconButton:hover {
		color: white;
	}
}

.whiteButtonText {
	font-size: 18px;
	font-weight: 650;
}

.filledButtonText {
	color: white;
	font-size: 18px;
	font-weight: 650;
}

.swiper-slide {
	display: flex;
	justify-content: center;

	padding-bottom: 60px;
}

// instagram
.post-item {
	position: relative;
	color: #fff;
}

.post-image {
	// border-radius: 15px;
	width: 100%;
	display: block;
	// box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
	cursor: pointer;
}

.post-video > video {
	// border-radius: 15px;
	//   width: 100% !important;
	display: block !important;
	// box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
	cursor: pointer !important;

	width: 429px !important;
	height: 429px !important;
	object-fit: cover !important;

	@media (max-width: 1400px) {
		width: 389px !important;
		height: 389px !important;
	}
	@media (max-width: 1280px) {
		width: 302px !important;
		height: 302px !important;
	}
}

.post-item-likes {
	display: flex;
	justify-content: center;
	align-items: center;
}

.post-item:hover .post-item-info,
.post-item:focus .post-item-info {
	display: flex;
	justify-content: center;
	align-items: center;
	// place-items: center;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

.post-item-info {
	display: none;
	// border-radius: 15px;
}

.post-item-info ul {
	padding: 0;
	margin: 0;
	display: flex;
	gap: 25px;
}

.post-item-info li {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

//google map
.map-container {
	width: 100%;
	height: 800px;
	// border-radius: 60px;
}
